<template>
  <div class="about">
    <h1>About me</h1>
    <div id="spacing"/>
    <div id="bio">
      <p>
        I am Abderrahmane Djaker a 19 years old mechanical engineering student at <i>University of Science and Technology Houari Boumediene</i>,
        actively learning and doing programming as a hobby. I have a strong interest in game developemnt with a reasonable experience in the domain.
        Art ? I like art, more precisely graphical design, making logos and abstract draft is something I'm doing for years.
      </p>
    </div>
    <div id="spacing"/>
    <h2>Reach me out</h2>
    <div id="spacing"/>
    <div id="icons">
      <SocialIcon icon="github" link="https://github.com/Abdera7mane"/>
      <SocialIcon icon="twitter" link="https://twitter.com/abdera7mane_me"/>
      <SocialIcon icon="discord" username="Abdera7mane#8910"/>
    </div>
  </div>
</template>

<script>
import SocialIcon from '@/components/models/SocialIcon.vue'

export default {
  props: {
    age: Number
  },
  components: {
    SocialIcon
  }
}
</script>

<style scoped>
#bio {
  text-align: left;
  align-items: center;
  width: 780px;
  margin: auto;
}

#bio p {
  font-size: larger;
}

#icons * {
  display: inline-block;
}

</style>