<template>
    <a id="icon" :href="link" target="_blank">
        <img :src="require(`@/assets/icons/${icon}.svg`)"/>
        <p id="username">{{ username }}</p>
    </a>
</template>

<script>
export default {
    name: 'SocialIcon',
    props: {
        icon: String,
        link: String,
        username: String
    }
}
</script>

<style>
#icon img {
    margin: 15px;
    height: 80px;
    widows: 80px;
    border-radius: 50%;
}

#username {
    font-size: 12px;
    position: absolute;
    color: black;

}

</style>